import React from "react"

import Layout from "../components/layout"
import CommonDedicatedTeam from "../components/dedicated-team/CommonDedicatedTeam"

const DedicatedTeamPhp = () => {
  return (
    <Layout pageTitle="Dedicated PHP Developers">
      {({ width }) => <CommonDedicatedTeam type="PHP" screenWidth={width} />}
    </Layout>
  )
}

export default DedicatedTeamPhp
